import { CrimeDataType } from '../../../../constants/crime_list_data';

export const remainTimeText = (data: CrimeDataType) => {
  if (!data.crimeTime) return undefined;

  const originData = data.crimeTime.replace(' 예고', '');
  const regex = /(\d+)년 (\d+)월 (\d+)일 (\d+)시 (\d+)분/;
  const match = originData.match(regex);
  if (!match) return undefined;

  const year = match[1];
  const month = match[2].padStart(2, '0'); // 월을 두 자리로 만듭니다.
  const day = match[3].padStart(2, '0'); // 일을 두 자리로 만듭니다.
  const hour = match[4];
  const minute = match[5];

  // 새로운 날짜 형식 문자열 생성
  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}`;

  const crimeTime = new Date(formattedDate);
  const now = new Date();
  // calc remainTime
  const remainTime = crimeTime.getTime() - now.getTime();
  const remainTimeMin = Math.floor(remainTime / 1000 / 60);
  const remainTimeHour = Math.floor(remainTimeMin / 60);
  const remainTimeDay = Math.floor(remainTimeHour / 24);

  if (remainTimeDay > 0) {
    return `${remainTimeDay}일 뒤`;
  }
  if (remainTimeHour > 0) {
    return `${remainTimeHour}시간 뒤`;
  }
  if (remainTimeMin > 0) {
    return `${remainTimeMin}분 뒤`;
  }

  return '';
};

export const isRemainTime = (data: CrimeDataType) => {
  if (!data.crimeTime) return undefined;

  const originData = data.crimeTime.replace(' 예고', '');
  const regex = /(\d+)년 (\d+)월 (\d+)일 (\d+)시 (\d+)분/;
  const match = originData.match(regex);
  if (!match) return undefined;

  const year = match[1];
  const month = match[2].padStart(2, '0'); // 월을 두 자리로 만듭니다.
  const day = match[3].padStart(2, '0'); // 일을 두 자리로 만듭니다.
  const hour = match[4];
  const minute = match[5];

  // 새로운 날짜 형식 문자열 생성
  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}`;

  const crimeTime = new Date(formattedDate);
  const now = new Date();
  // calc remainTime
  const remainTime = crimeTime.getTime() - now.getTime();

  // console.log(remainTime);
  return remainTime > 0;
};
