import { ReactElement } from 'react';
import { MyLocationRounded } from '@mui/icons-material';
import styled from '@emotion/styled';
import { CureentPositionType } from '../../hooks/useMapCenter';
import { useMap } from 'react-kakao-maps-sdk';
import { useFormContext } from 'react-hook-form';
import useDefaultSelectedItem from '../../../../hooks/useDefaultSelectedItem';
// import useSendNativeEventBridge from '../../../../hooks/useSendNativeEventBridge';

function MyLocationController(): ReactElement {
  const map = useMap();
  const { watch } = useFormContext();
  const currentLocationValue: CureentPositionType = watch('currentLocation');
  // const { sendToNative } = useSendNativeEventBridge();

  useDefaultSelectedItem();

  return (
    <Wrapper className="my-location">
      <Button
        onClick={async () => {
          if (currentLocationValue) {
            map.panTo(new kakao.maps.LatLng(currentLocationValue.lat, currentLocationValue.lng));
            return;
          }
          // sendToNative('permissionLocation', {});

          // const result = await moveToMyLocation({
          //   mapEl: map,
          //   setCurrentLocationIconHandler,
          //   panToMyLocation: true,
          // });
          // if (!result) {
          //   alert('위치정보를 찾을 수 없습니다.' + currentLocationValue ?? '');
          // }
        }}
      >
        <MyLocationRounded style={{}} />
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 220px;
  left: 3px;
  z-index: 1;

  border-radius: 10px;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
`;

export default MyLocationController;
