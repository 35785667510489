import { ReactElement } from 'react';
// import { MarkerClusterer } from 'react-kakao-maps-sdk';

// import { useFormContext } from 'react-hook-form';
import { CRIME_DATA_LIST } from '../../../../constants/crime_list_data';
import CrimeMarkers from './CrimeMarkers';

function CrimeList(): ReactElement {
  // const { watch } = useFormContext();

  const crimeList = CRIME_DATA_LIST;

  return (
    <>
      {crimeList.map((item, index) => {
        if (!item.geoLocation) return;
        return (
          <CrimeMarkers
            key={'marker_' + item.type + '_' + index + '_' + item.location}
            position={{ lat: item.geoLocation.y, lng: item.geoLocation.x }}
            item={item}
          />
        );
      })}
    </>
  );
}

export default CrimeList;
