export type CrimeDataType = {
  id: number;
  type: string;
  location: string;
  crimeType: string;
  crimeTime?: string;
  geoLocation?: {
    x: number;
    y: number;
    range?: number;
  };
  timeline: {
    type: string;
    createdAt: string;
    link: string;
  }[];
};

export const CRIME_DATA_LIST: CrimeDataType[] = [
  {
    type: '예고',
    location: '강릉 일대 상가',
    crimeType: '살인 예고',
    geoLocation: {
      y: 37.751853,
      x: 128.876057,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 22시 38분',
        link: 'https://www.mbceg.co.kr/post/108520',
      },
    ],
    id: 1,
  },
  {
    type: '예고',
    location: '광주신세계',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 35.1605431,
      x: 126.8826817,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 16시 50분',
        link: 'https://news.imaeil.com/page/view/2023080818563801012',
      },
    ],
    id: 2,
  },
  {
    type: '예고',
    location: '광주송정역',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 35.13750641613289,
      x: 126.79147478093012,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 16시 50분',
        link: 'https://news.imaeil.com/page/view/2023080818563801012',
      },
    ],
    id: 3,
  },
  {
    type: '예고',
    location: '광주 인근 터미널',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 35.161142,
      x: 126.8799,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 16시 50분',
        link: 'https://news.imaeil.com/page/view/2023080818563801012',
      },
    ],
    id: 4,
  },
  {
    type: '예고',
    location: '광주챔피언스필드',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 35.168245296457684,
      x: 126.88913396621837,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 16시 50분',
        link: 'https://news.imaeil.com/page/view/2023080818563801012',
      },
    ],
    id: 5,
  },
  {
    type: '예고',
    location: '백화점 야구장 터미널 기차역',
    crimeType: '칼부림, 폭탄 예고',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 12시 36분',
        link: 'https://twitter.com/gimsiwol1165926/status/1688756066174078976?s=46',
      },
    ],
    id: 6,
  },
  {
    type: '검거완료',
    location: '포천시 신북면',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.9448674,
      x: 127.171035,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 8일 11시 00분',
        link: 'https://n.news.naver.com/article/057/0001761336?cds=news_media_pc',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 8일 11시 00분',
        link: 'https://n.news.naver.com/article/057/0001761336?cds=news_media_pc',
      },
    ],
    id: 7,
  },
  {
    type: '예고',
    location: '김해 신세계백화점',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 35.229051,
      x: 128.8725425,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 8일 00시 00분',
        link: 'https://www.news1.kr/articles/?5134332',
      },
    ],
    id: 8,
  },
  {
    type: '검거완료',
    location: '대전역',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 6일 22시 30분',
    geoLocation: {
      y: 36.331519,
      x: 127.433637,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 22시 30분',
        link: 'https://n.news.naver.com/article/421/0006978257?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 23시 55분',
        link: 'https://n.news.naver.com/article/421/0006978257?sid=102',
      },
    ],
    id: 9,
  },
  {
    type: '예고',
    location: '인천국제공항',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 37.4417632,
      x: 126.4615008,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 16시 00분',
        link: 'https://www.edaily.co.kr/news/read?newsId=03421046635705352&mediaCodeNo=257&OutLnkChk=Y',
      },
    ],
    id: 10,
  },
  {
    type: '검거완료',
    location: '동대구역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 7일 15시 54분',
    geoLocation: {
      y: 35.879667,
      x: 128.628476,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 7일 15시 54분',
        link: 'https://news.mt.co.kr/mtview.php?no=2023080722355716556',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://www.chosun.com/national/national_general/2023/08/07/OAQBRHNB2JD3TCPHOU5Q7IXLO4/',
      },
    ],
    id: 11,
  },
  {
    type: '검거완료',
    location: '춘천',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 4일 19시 30분',
    geoLocation: {
      y: 37.8897796,
      x: 127.7398952,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 18시 46분',
        link: 'https://m.yna.co.kr/amp/view/AKR20230808132800062',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 13시 20분',
        link: 'https://m.yna.co.kr/amp/view/AKR20230808132800062',
      },
    ],
    id: 12,
  },
  {
    type: '검거완료',
    location: '울산 북구 일대 초등학교',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 7일 00시 00분',
    geoLocation: {
      y: 35.5827044,
      x: 129.3612394,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 19시 27분',
        link: 'https://www.seoul.co.kr/news/newsView.php?id=20230807500052',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 12시 14분',
        link: 'https://www.instagram.com/p/Cvoi-Nyrc21/',
      },
    ],
    id: 13,
  },
  {
    type: '검거완료',
    location: '포항 소재 숙소',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 6일 20시 00분',
    geoLocation: {
      y: 36.0191816,
      x: 129.3432983,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 14시 22분',
        link: 'https://n.news.naver.com/article/079/0003799321',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 08시 00분',
        link: 'https://n.news.naver.com/article/079/0003799321',
      },
    ],
    id: 14,
  },
  {
    type: '예고',
    location: '일산중앙광장',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 9일 17시 00분 예고',
    geoLocation: {
      y: 37.6584643,
      x: 126.7707094,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 01시 22분',
        link: 'http://www.1gan.co.kr/news/articleView.html?idxno=229123',
      },
    ],
    id: 15,
  },
  {
    type: '예고',
    location: '김해국제공항',
    crimeType: '폭탄테러, 살인 예고',
    geoLocation: {
      y: 35.1718967,
      x: 128.948517,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 00시 18분',
        link: 'https://www.busan.com/view/busan/view.php?code=2023080710292916438',
      },
    ],
    id: 16,
  },
  {
    type: '예고',
    location: '강남 시장',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 37.5184859,
      x: 127.0207167,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://twitter.com/make_save_korea/status/1688500879144763392?s=46&t=lKgnnlCf9-EmfSckbzNNoA',
      },
    ],
    id: 17,
  },
  {
    type: '예고',
    location: '국회도서관',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 9일 00시 00분 예고',
    geoLocation: {
      y: 37.5311396,
      x: 126.9170175,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://www.joongang.co.kr/article/25183368#home',
      },
    ],
    id: 18,
  },
  {
    type: '검거완료',
    location: '충북 청주시 서원구 산남동',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 7일 12시 46분',
    geoLocation: {
      y: 36.6116926,
      x: 127.4685101,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://n.news.naver.com/article/003/0012017300',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://n.news.naver.com/article/003/0012017300',
      },
    ],
    id: 19,
  },
  {
    type: '검거완료',
    location: '홍대 상상마당',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 6일 14시 00분',
    geoLocation: {
      y: 37.550912,
      x: 126.921127,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 12시 05분',
        link: 'https://n.news.naver.com/article/003/0012019041?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://n.news.naver.com/article/003/0012019041?sid=102',
      },
    ],
    id: 20,
  },
  {
    type: '예고',
    location: '가좌시장',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 7일 00시 00분 예고',
    geoLocation: {
      y: 37.4933787,
      x: 126.6845446,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://twitter.com/make_save_korea/status/1688500879144763392?s=46&t=lKgnnlCf9-EmfSckbzNNoA',
      },
    ],
    id: 21,
  },
  {
    type: '검거완료',
    location: '천안터미널',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 19시 00분',
    geoLocation: {
      y: 36.819908,
      x: 127.1565357,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 11시 00분',
        link: 'https://n.news.naver.com/article/001/0014117249?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 7일 00시 00분',
        link: 'https://www.newspim.com/news/view/20230807000988',
      },
    ],
    id: 22,
  },
  {
    type: '예고',
    location: '대구국제공항',
    crimeType: '폭탄테러, 칼부림, 차량테러',
    crimeTime: '2023년 8월 9일 00시 00분 예고',
    geoLocation: {
      y: 35.9001012,
      x: 128.6377214,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 23시 16분',
        link: 'https://news.imaeil.com/page/view/2023080709465192146',
      },
    ],
    id: 23,
  },
  {
    type: '예고',
    location: '제주국제공항',
    crimeType: '폭탄테러, 칼부림',
    crimeTime: '2023년 8월 7일 14시 00분 예고',
    geoLocation: {
      y: 33.5067792,
      x: 126.492901,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 21시 07분',
        link: 'https://news.mt.co.kr/mtview.php?no=2023080707335191578',
      },
    ],
    id: 24,
  },
  {
    type: '예고',
    location: '김포국제공항',
    crimeType: '폭탄테러',
    geoLocation: {
      y: 37.5658264,
      x: 126.8010869,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 20시 50분',
        link: 'https://biz.sbs.co.kr/article/20000130361',
      },
    ],
    id: 25,
  },
  {
    type: '예고',
    location: '이수역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 8일 00시 00분 예고',
    geoLocation: {
      y: 37.4853818,
      x: 126.9821433,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 17시 39분',
        link: 'https://archive.md/p7QY1',
      },
    ],
    id: 26,
  },
  {
    type: '허위',
    location: '잠실 롯데월드',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 8일 00시 00분',
    geoLocation: {
      y: 37.5111158,
      x: 127.098167,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 15시 21분',
        link: 'https://www.instiz.net/name/55475510',
      },
      {
        type: '허위',
        createdAt: '2023년 8월 6일 17시 28분',
        link: 'https://twitter.com/make_save_korea/status/1687679142240874496?s=61&t=gegUCY0mTu3kOhAegcDGsQ',
      },
    ],
    id: 27,
  },
  {
    type: '검거완료',
    location: '대전 중구 은행동',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 7일 20시 10분',
    geoLocation: {
      y: 36.3296378,
      x: 127.4268736,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 00시 53분',
        link: 'https://www.newspim.com/news/view/20230806000123',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 6일 10시 45분',
        link: 'https://www.newspim.com/news/view/20230806000123',
      },
    ],
    id: 28,
  },
  {
    type: '검거완료',
    location: '계양역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 19시 00분',
    geoLocation: {
      y: 37.5706698,
      x: 126.7369404,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 17시 47분',
        link: 'https://n.news.naver.com/article/001/0014114747?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 6일 08시 00분',
        link: 'https://n.news.naver.com/article/001/0014114747?sid=102',
      },
    ],
    id: 29,
  },
  {
    type: '검거완료',
    location: '광산구 일대 고등학교',
    crimeType: '칼부림',
    geoLocation: {
      y: 35.164800029369154,
      x: 126.75333917299795,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 04시 00분',
        link: 'http://www.ikbc.co.kr/article/view/kbc202308060027',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 6일 00시 00분',
        link: 'https://m.hankookilbo.com/News/Read/A2023080616450003581',
      },
    ],
    id: 30,
  },
  {
    type: '예고',
    location: '광산구 장덕동 일대 초등학교',
    crimeType: '칼부림',
    geoLocation: {
      y: 35.19466518872941,
      x: 126.81019309548253,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 01시 00분',
        link: 'https://news.sarangbang.com/detail/article/4440751?utm_source=sarangbang&utm_medium=push&utm_campaign=0807',
      },
    ],
    id: 31,
  },
  {
    type: '예고',
    location: '강남역 인근 성형외과',
    crimeType: '폭행/범죄 예고글',
    geoLocation: {
      y: 37.4969353,
      x: 127.029537,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 00시 00분',
        link: 'https://m.dcinside.com/board/baseball_new11/8641839?page=3',
      },
    ],
    id: 32,
  },
  {
    type: '검거완료',
    location: '안성 소재 PC방 앞',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 7일 19시 00분',
    geoLocation: {
      y: 37.0078632,
      x: 127.2798557,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://www.news1.kr/articles/5132052',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 6일 00시 00분',
        link: 'https://www.news1.kr/articles/5132052',
      },
    ],
    id: 33,
  },
  {
    type: '예고',
    location: '서울예고',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 7일 00시 00분 예고',
    geoLocation: {
      y: 37.6056133,
      x: 126.9690396,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 6일 00시 00분',
        link: 'https://twitter.com/make_save_korea/status/1688500363824177152?s=46&t=32bY2-cOwtx2W5AFIm9moQ',
      },
    ],
    id: 34,
  },
  {
    type: '검거완료',
    location: '원주역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 6일 18시 00분',
    geoLocation: {
      y: 37.315816,
      x: 127.9205822,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 02시 18분',
        link: 'http://www.kado.net/news/articleView.html?idxno=1197393',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 23시 00분',
        link: 'https://n.news.naver.com/article/001/0014114446',
      },
    ],
    id: 35,
  },
  {
    type: '검거완료',
    location: '용산',
    crimeType: '칼부림 예고',
    geoLocation: {
      y: 37.5313805,
      x: 126.9798839,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 12시 34분',
        link: 'https://n.news.naver.com/article/003/0012019041?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 22시 45분',
        link: 'https://n.news.naver.com/article/003/0012019041?sid=102',
      },
    ],
    id: 36,
  },
  {
    type: '검거완료',
    location: '서면역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 17시 00분',
    geoLocation: {
      y: 35.1578157,
      x: 129.0600331,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 01시 00분',
        link: 'https://n.news.naver.com/article/005/0001628601',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 19시 47분',
        link: 'https://n.news.naver.com/mnews/article/001/0014114970?rc=N&ntype=RANKING',
      },
    ],
    id: 37,
  },
  {
    type: '검거완료',
    location: '강남 일대 성형외과',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 4일 00시 00분',
    geoLocation: {
      y: 37.5002724,
      x: 127.0287844,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 23시 02분',
        link: 'https://n.news.naver.com/article/001/0014114064?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 19시 21분',
        link: 'https://n.news.naver.com/article/001/0014114064?sid=102',
      },
    ],
    id: 38,
  },
  {
    type: '예고',
    location: '홍대',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 00시 00분 예고',
    geoLocation: {
      y: 37.5551463,
      x: 126.9215309,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 18시 14분',
        link: 'https://gall.dcinside.com/board/view/?id=baseball_new11&no=8630170',
      },
    ],
    id: 39,
  },
  {
    type: '예고',
    location: '강남역',
    crimeType: '칼부림 예고글',
    geoLocation: {
      y: 37.4979052,
      x: 127.0275777,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 18시 10분',
        link: 'https://gall.dcinside.com/board/view/?id=comic_new3&no=11868912',
      },
    ],
    id: 40,
  },
  {
    type: '예고',
    location: '대구 삼성라이온즈파크',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 00시 00분 예고',
    geoLocation: {
      y: 35.8410136,
      x: 128.6819955,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 15시 39분',
        link: 'https://www.sedaily.com/NewsView/29TAH45ALF',
      },
    ],
    id: 41,
  },
  {
    type: '검거완료',
    location: '용인시 에버랜드',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 15시 00분',
    geoLocation: {
      y: 37.2939104,
      x: 127.2025664,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 11시 51분',
        link: 'https://news.imaeil.com/page/view/2023080517255690212',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 13시 08분',
        link: 'https://news.imaeil.com/page/view/2023080517255690212',
      },
    ],
    id: 42,
  },
  {
    type: '검거완료',
    location: '부평역 로데오거리',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 22시 00분',
    geoLocation: {
      y: 37.4918404,
      x: 126.7235918,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 09시 49분',
        link: 'https://i.namu.wiki/i/p_70CTGTD5_AM0g5ooTJOfv8mC8QDRCzT_RA05OGPIidMmD_7p8MFJzElLDXBfgCHmIWfSdUL3NhKV6pOeg3Qkhuzg8B9iKyi2c2vLKrMqpI3KqFr6EuB0IGzLrCJ7hT-zbKL0tUgEto7_fSAlJkLg.webp',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 13시 00분',
        link: 'https://n.news.naver.com/article/018/0005545662?cds=news_edit',
      },
    ],
    id: 43,
  },
  {
    type: '검거완료',
    location: '혜화역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 15시 00분',
    geoLocation: {
      y: 37.58217,
      x: 127.001893,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 09시 00분',
        link: 'https://n.news.naver.com/article/008/0004921576?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 10시 30분',
        link: 'https://n.news.naver.com/article/008/0004921576?sid=102',
      },
    ],
    id: 44,
  },
  {
    type: '검거완료',
    location: '구미역',
    crimeType: '칼부림 예고글',
    geoLocation: {
      y: 36.1283125,
      x: 128.3308125,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 22시 48분',
        link: 'https://www.donga.com/news/Society/article/all/20230805/120574100/1',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 03시 30분',
        link: 'https://www.donga.com/news/Society/article/all/20230805/120574100/1',
      },
    ],
    id: 45,
  },
  {
    type: '검거완료',
    location: '천안시 서북구 두정동',
    crimeType: '칼부림 예고',
    geoLocation: {
      y: 36.8334411,
      x: 127.1433118,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 02시 24분',
        link: 'https://www.joongang.co.kr/article/25182693#home',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://www.joongang.co.kr/article/25182693#home',
      },
    ],
    id: 46,
  },
  {
    type: '예고',
    location: '신림역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 6일 18시 00분 예고',
    geoLocation: {
      y: 37.4631968,
      x: 126.9358124,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 28분',
        link: 'https://www.fmkorea.com/best/6038592203',
      },
    ],
    id: 47,
  },
  {
    type: '예고',
    location: '강남역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 9일 17시 00분 예고',
    geoLocation: {
      y: 37.499237,
      x: 127.026364,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 25분',
        link: 'https://www.fmkorea.com/best/6038592203',
      },
    ],
    id: 48,
  },
  {
    type: '검거완료',
    location: '전국 오락실 일대 (게임플라자)',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 00시 00분',
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://www.gamemeca.com/view.php?gid=1739906',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 21시 38분',
        link: 'https://www.gamemeca.com/view.php?gid=1739906',
      },
    ],
    id: 49,
  },
  {
    type: '검거완료',
    location: '용산구',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.5313805,
      x: 126.9798839,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 19시 00분',
        link: 'https://n.news.naver.com/article/025/0003298750?sid=102',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://n.news.naver.com/article/025/0003298750?sid=102',
      },
    ],
    id: 50,
  },
  {
    type: '검거완료',
    location: '고양시 상가',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 00시 00분',
    geoLocation: {
      y: 37.6582159,
      x: 126.8320138,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://n.news.naver.com/article/654/0000048377',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://n.news.naver.com/article/654/0000048377',
      },
    ],
    id: 51,
  },
  {
    type: '예고',
    location: '송도달빛축제공원',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 6일 00시 00분 예고',
    geoLocation: {
      y: 37.407141,
      x: 126.625873,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 5일 00시 00분',
        link: 'https://www.sportalkorea.com/news/o_view.php?gisa_uniq=202308061557773730',
      },
    ],
    id: 52,
  },
  {
    type: '예고',
    location: '수원역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 5일 00시 00분 예고',
    geoLocation: {
      y: 37.2661617,
      x: 126.9998207,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 21시 54분',
        link: 'https://i.namu.wiki/i/S1b7N3DoQQ52KcuFd9j0Ynp0h7jHWL_vYXnedJGMvA3hgGfeKM_pXx8C-x83K2syTmT3xdtK9l0BIZ6Nkc2sBn9QWIIHYf3oelQrMWeXMQn5BVgO75ERqHl_TRGLdQ7-Yort6ocfDZRbjwEQQvtsXw.webp',
      },
    ],
    id: 53,
  },
  {
    type: '검거완료',
    location: '용인시 포곡읍',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 21시 30분',
    geoLocation: {
      y: 37.2904896,
      x: 127.2175717,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 4일 21시 30분',
        link: 'https://imnews.imbc.com/news/2023/society/article/6511135_36126.html',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 21시 30분',
        link: 'https://imnews.imbc.com/news/2023/society/article/6511135_36126.html',
      },
    ],
    id: 54,
  },
  {
    type: '검거완료',
    location: '미사역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 5일 12시 00분',
    geoLocation: {
      y: 37.5630475,
      x: 127.1929576,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 14시 30분',
        link: 'https://www.yna.co.kr/view/AKR20230805024800061',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 20시 58분',
        link: 'https://www.yna.co.kr/view/AKR20230805024800061',
      },
    ],
    id: 55,
  },
  {
    type: '검거완료',
    location: '한티역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 22시 00분',
    geoLocation: {
      y: 37.496267,
      x: 127.0528531,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 23시 04분',
        link: 'https://archive.is/FgkRG',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 20시 18분',
        link: 'https://www.topstarnews.net/news/articleView.html?idxno=15377289',
      },
    ],
    id: 56,
  },
  {
    type: '검거완료',
    location: '대구대학교',
    crimeType: '살인 예고',
    geoLocation: {
      y: 35.9021687,
      x: 128.849098,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 14시 38분',
        link: 'https://www.yna.co.kr/view/AKR20230804148551053',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 19시 38분',
        link: 'https://www.yna.co.kr/view/AKR20230804148551053',
      },
    ],
    id: 57,
  },
  {
    type: '검거완료',
    location: '재송역',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 7일 19시 00분',
    geoLocation: {
      y: 35.1886765,
      x: 129.1208025,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 17시 27분',
        link: 'https://www.sedaily.com/NewsView/29TA11XCJD',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 19시 30분',
        link: 'https://www.sedaily.com/NewsView/29TA11XCJD',
      },
    ],
    id: 58,
  },
  {
    type: '검거완료',
    location: '왕십리역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 4일 16시 00분',
    geoLocation: {
      y: 37.5610365,
      x: 127.0354919,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 12시 04분',
        link: 'https://n.news.naver.com/article/025/0003298696',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 17시 50분',
        link: 'https://n.news.naver.com/article/025/0003298696',
      },
    ],
    id: 59,
  },
  {
    type: '예고',
    location: '수원역',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 5일 01시 00분 예고',
    geoLocation: {
      y: 37.2661617,
      x: 126.9998207,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 17시 36분',
        link: 'https://twitter.com/make_save_korea/status/1687496170510462976?t=MmBT8nQRnuRKc_4IUiJPMw&s=19',
      },
    ],
    id: 60,
  },
  {
    type: '예고',
    location: '인천국제공항',
    crimeType: '폭탄테러, 칼부림, 차량테러',
    crimeTime: '2023년 8월 4일 20시 00분 예고',
    geoLocation: {
      y: 37.4417632,
      x: 126.4615008,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 16시 52분',
        link: 'https://archive.is/VjzNH/c30cc07a71f773c4bc5a645c2fa58c89d72d3ae6.jpg',
      },
    ],
    id: 61,
  },
  {
    type: '검거완료',
    location: '모란역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 4일 19시 00분',
    geoLocation: {
      y: 37.4333822,
      x: 127.1295978,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 14시 30분',
        link: 'https://www.asiatoday.co.kr/view.php?key=20230804010002563',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 16시 45분',
        link: 'https://www.asiatoday.co.kr/view.php?key=20230804010002563',
      },
    ],
    id: 62,
  },
  {
    type: '예고',
    location: '강님구 대치동 시대인재 학원',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 6일 12시 00분 예고',
    geoLocation: {
      y: 37.4979621,
      x: 127.0588845,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 16시 00분',
        link: 'https://www.newspim.com/news/view/20230804000939',
      },
    ],
    id: 63,
  },
  {
    type: '검거완료',
    location: '고양 킨텍스',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 6일 00시 00분',
    geoLocation: {
      y: 37.6689536,
      x: 126.7458565,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 14시 00분',
        link: 'http://www.gameshot.net/common/con_view.php?code=GA64ccd96f7c62f',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 16시 00분',
        link: 'https://news.sbs.co.kr/news/endPage.do?news_id=N1007297011',
      },
    ],
    id: 64,
  },
  {
    type: '검거완료',
    location: '의정부역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 6일 00시 00분',
    geoLocation: {
      y: 37.7387295,
      x: 127.0458908,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 01시 57분',
        link: 'https://www.joongang.co.kr/article/25182566#home',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 16시 00분',
        link: 'https://www.joongang.co.kr/article/25182566#home',
      },
    ],
    id: 65,
  },
  {
    type: '예고',
    location: '광장중학교',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.547095,
      x: 127.101708,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 13시 54분',
        link: 'https://i.namu.wiki/i/qrYFc4wkLtnktVe3KWwnPkboYycXUh28cnXYdNSH1VWyeAEMxT5Mcy1NlxCO-djHJCtGJFQ8BXE8WtLGsaH-Pz73GE8atA6AGSfYwWHwv0FzIPZ-RbxLmk3y9Yl1928VVS5KKV1CKA-hP3lvJbbuNg.webp',
      },
    ],
    id: 66,
  },
  {
    type: '예고',
    location: '성복역',
    crimeType: '살인 예고',
    crimeTime: '2023년 8월 4일 20시 00분 예고',
    geoLocation: {
      y: 37.313557,
      x: 127.080341,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 13시 10분',
        link: 'https://n.news.naver.com/article/215/0001117663?sid=102',
      },
    ],
    id: 67,
  },
  {
    type: '검거완료',
    location: '서울고속버스터미널',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 10시 39분',
    geoLocation: {
      y: 37.50568,
      x: 127.005847,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 4일 10시 39분',
        link: 'https://news.imaeil.com/page/view/2023080411484095671',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 4일 10시 39분',
        link: 'https://news.imaeil.com/page/view/2023080411484095671',
      },
    ],
    id: 68,
  },
  {
    type: '예고',
    location: '이대역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 23시 00분 예고',
    geoLocation: {
      y: 37.5567299,
      x: 126.9458896,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 10시 15분',
        link: 'https://pbs.twimg.com/media/F2qRq8lagAAISrl?format=webp&name=medium',
      },
    ],
    id: 69,
  },
  {
    type: '예고',
    location: '여의도',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.5216504,
      x: 126.9241705,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 10시 08분',
        link: 'https://gall.dcinside.com/board/view/?id=immovables&no=6322218',
      },
    ],
    id: 70,
  },
  {
    type: '예고',
    location: '대통령 관저',
    crimeType: '폭탄 테러',
    crimeTime: '2023년 8월 5일 17시 00분 예고',
    geoLocation: {
      y: 37.5393584,
      x: 127.0082468,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 02시 10분',
        link: 'https://n.news.naver.com/article/005/0001628597',
      },
    ],
    id: 71,
  },
  {
    type: '예고',
    location: '강남역 사거리',
    crimeType: '칼부림, 차량 테러',
    crimeTime: '2023년 8월 4일 19시 00분 예고',
    geoLocation: {
      y: 37.4979052,
      x: 127.0275777,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 02시 04분',
        link: 'https://archive.is/AMZun',
      },
    ],
    id: 72,
  },
  {
    type: '허위',
    location: '전주시 송천동 천마산로',
    crimeType: '칼부림 예고',
    crimeTime: '2023년 8월 5일 18시 00분',
    geoLocation: {
      y: 35.8642026,
      x: 127.1299435,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 02시 00분',
        link: 'https://www.jjan.kr/article/20230804580116',
      },
      {
        type: '허위',
        createdAt: '2023년 8월 4일 00시 00분',
        link: 'https://news.kbs.co.kr/news/view.do?ncd=7741270',
      },
    ],
    id: 73,
  },
  {
    type: '예고',
    location: '현대백화점 압구정본점',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.5273268,
      x: 127.0274265,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 00시 00분',
        link: 'https://n.news.naver.com/article/421/0006972317?sid=101',
      },
    ],
    id: 74,
  },
  {
    type: '예고',
    location: '논현동',
    crimeType: '칼부림',
    geoLocation: {
      y: 37.5136787,
      x: 127.0317124,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 4일 00시 00분',
        link: 'https://n.news.naver.com/article/629/0000231215',
      },
    ],
    id: 75,
  },
  {
    type: '예고',
    location: '서현역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 00시 00분 예고',
    geoLocation: {
      y: 37.3822656,
      x: 127.1344396,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 19시 09분',
        link: 'http://file3.instiz.net/data/cached_img/upload/2023/08/03/20/8bc97f22d5b8dcb10852f766df16264f.jpg',
      },
    ],
    id: 76,
  },
  {
    type: '예고',
    location: '잠실역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 09시 00분 예고',
    geoLocation: {
      y: 37.513238,
      x: 127.0999314,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 19시 02분',
        link: 'https://www.mk.co.kr/news/society/10800646',
      },
    ],
    id: 77,
  },
  {
    type: '예고',
    location: '강남역 5번출구',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 19시 00분 예고',
    geoLocation: {
      y: 37.4953149,
      x: 127.028535,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 19시 00분',
        link: 'https://n.news.naver.com/article/055/0001078849',
      },
    ],
    id: 78,
  },
  {
    type: '검거완료',
    location: '광주광역시 서구 술집',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 3일 18시 50분',
    geoLocation: {
      y: 35.13551116143639,
      x: 126.85025914167433,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 3일 18시 50분',
        link: 'https://www.news1.kr/articles/?5131342',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 3일 18시 50분',
        link: 'https://www.news1.kr/articles/?5131342',
      },
    ],
    id: 79,
  },
  {
    type: '검거완료',
    location: '서현역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 3일 17시 59분',
    geoLocation: {
      y: 37.3822656,
      x: 127.1344396,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 8월 3일 17시 59분',
        link: 'https://www.donga.com/news/Society/article/all/20230803/120551759/1',
      },
      {
        type: '검거완료',
        createdAt: '2023년 8월 3일 18시 05분',
        link: 'https://www.chosun.com/national/national_general/2023/08/03/C4WPYO7JRRA73BHEF6NFKJM7CU/',
      },
    ],
    id: 80,
  },
  {
    type: '예고',
    location: '오리역',
    crimeType: '칼부림',
    crimeTime: '2023년 8월 4일 18시 00분 예고',
    geoLocation: {
      y: 37.3398302,
      x: 127.108927,
      range: 500,
    },
    timeline: [
      {
        type: '예고',
        createdAt: '2023년 8월 3일 00시 00분',
        link: 'https://www.chosun.com/national/regional/2023/08/04/XNOG4VL6RZCTXCLHW4VNGEBFSE/',
      },
    ],
    id: 81,
  },
  {
    type: '검거완료',
    location: '신림역',
    crimeType: '칼부림',
    crimeTime: '2023년 7월 21일 14시 07분',
    geoLocation: {
      y: 37.48394,
      x: 126.928801,
      range: 500,
    },
    timeline: [
      {
        type: '발생',
        createdAt: '2023년 7월 21일 14시 07분',
        link: 'https://news.kbs.co.kr/news/view.do?ncd=7729781',
      },
      {
        type: '검거완료',
        createdAt: '2023년 7월 21일 14시 20분',
        link: 'https://www.chosun.com/national/national_general/2023/07/21/EMTDCO4D3VCMFGZYDROJ6UOMHE/',
      },
    ],
    id: 82,
  },
];
