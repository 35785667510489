import { useEffect, useRef } from 'react';

interface Props {
  scrollEle?: HTMLDivElement;
}

const useMouseDragScroll = ({ scrollEle }: Props) => {
  const posRef = useRef({
    top: 0,
    left: 0,
    x: 0,
    y: 0,
  });

  const mouseMoveHandler = function (e: MouseEvent) {
    if (!scrollEle) return;
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    // Scroll the element
    scrollEle.scrollTop = posRef.current.top - dy;
    scrollEle.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    if (!scrollEle) return;
    scrollEle.style.cursor = 'grab';
    scrollEle.style.removeProperty('user-select');

    scrollEle.removeEventListener('mousemove', mouseMoveHandler);
    scrollEle.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = function (e: MouseEvent) {
    if (!scrollEle) return;
    posRef.current = {
      // The current scroll
      left: scrollEle.scrollLeft,
      top: scrollEle.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
    scrollEle.addEventListener('mousemove', mouseMoveHandler);
    scrollEle.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() => {
    if (!scrollEle) return;
    scrollEle.addEventListener('mousedown', mouseDownHandler);

    return () => {
      scrollEle.removeEventListener('mousedown', mouseDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollEle]);

  return { mouseUpHandler, mouseMoveHandler };
};

export default useMouseDragScroll;
