import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import useMouseDragScroll from './useMouseDragScroll';
import { CRIME_DATA_LIST } from '../../../../constants/crime_list_data';
import BoxItem from './BoxItem';
import { isRemainTime } from './dateUtil';

const SideInfoList = () => {
  const WrapperRef = React.useRef<HTMLDivElement>(null);

  useMouseDragScroll({ scrollEle: WrapperRef.current as HTMLDivElement });

  const crimeList = useMemo(
    () =>
      CRIME_DATA_LIST.sort((a, b) => {
        const isRemainA = isRemainTime(a);
        const isRemainB = isRemainTime(b);

        if (isRemainA && !isRemainB) {
          return -1;
        }

        if (!isRemainA && isRemainB) {
          return 1;
        }

        return 0;
      }),
    []
  );

  return (
    <Wrapper ref={WrapperRef}>
      {crimeList.map((el, idx) => {
        return <BoxItem key={idx} data={el} />;
      })}
    </Wrapper>
  );
};

export default SideInfoList;

const Wrapper = styled.div`
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 10px;

  padding: 10px;

  overflow-y: auto;
  overflow-x: visible;

  flex: 1;
`;
