import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CRIME_DATA_LIST } from '../constants/crime_list_data';
import { useMap } from 'react-kakao-maps-sdk';

const useDefaultSelectedItem = () => {
  const map = useMap();
  const { setValue } = useFormContext();

  useEffect(() => {
    //get querystring
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const crimeId = urlParams.get('id');

    if (!crimeId) return;

    const crimeData = CRIME_DATA_LIST.find((item) => item.id === Number(crimeId));

    if (!crimeData) return;

    setValue('selectedItem', crimeData);
    if (!crimeData.geoLocation) return;
    const moveLatLon = new kakao.maps.LatLng(crimeData.geoLocation.y, crimeData.geoLocation.x);
    map.panTo(moveLatLon);
  }, [map, setValue]);
};

export default useDefaultSelectedItem;
