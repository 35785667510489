import { CrimeDataType } from '../../../../constants/crime_list_data';
import styled from '@emotion/styled';
import { Divider, Snackbar } from '@mui/material';
// import { useFormContext } from 'react-hook-form';
import { useMap } from 'react-kakao-maps-sdk';
import { remainTimeText } from './dateUtil';
import IosShareIcon from '@mui/icons-material/IosShare';
import React from 'react';

interface Props {
  data: CrimeDataType;
  isModal?: boolean;
}

const BoxItem = ({ data, isModal = false }: Props) => {
  const map = useMap();
  const [openShareToast, setOpenShareToast] = React.useState(false);
  // const { setValue, watch } = useFormContext();

  const isHappened = data.timeline.some((el) => el.type === '발생');
  const isClosedCrime = data.timeline.some(
    (el) => el.type === '발생' || el.type === '검거완료' || el.type === '허위'
  );

  const LeftRenderText = () => {
    if (data.type === '예고') return '⚠️';
    if (data.type === '검거완료') return '👮🏻‍♀️';
    if (data.type === '허위') return '🤬';
    return '🔫💣🔪';
  };

  const CrimeTypeRenderEmoji = () => {
    if (data.crimeType.includes('살인')) return '⚠️';
    if (data.crimeType === '폭탄 테러') return '💣';
    if (data.crimeType.includes('칼부림')) return '🔪';
    return '🔫🔪';
  };

  // eslint-disable-next-line
  const handleShareButton = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(`https://crime-map.pages.dev/?id=${data.id}`);
    setOpenShareToast(true);
  };

  return (
    <Wrapper
      type={isHappened ? '발생' : data.type}
      isModal={isModal}
      onClick={() => {
        if (!data.geoLocation) return;
        const moveLatLon = new kakao.maps.LatLng(data.geoLocation.y, data.geoLocation.x);
        map.panTo(moveLatLon);

        // setValue('selectedItem', data);
      }}
    >
      <Snackbar
        open={openShareToast}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="링크가 복사되었어요."
        onClose={() => {
          setOpenShareToast(false);
        }}
      />
      <div className="left-color-tag" />
      <Header>
        <span className="crime-type">
          {CrimeTypeRenderEmoji()} {data.crimeType}
        </span>
        <span className="remain-time">
          {!isClosedCrime && remainTimeText(data)}

          <CopyButton onClick={handleShareButton}>
            <IosShareIcon sx={{ fontSize: '19px', color: 'black' }} />
          </CopyButton>
        </span>
      </Header>

      <Divider />
      <DetailInfoBoxWrapper className="detail">
        <div className="left-timeline-line" />
        <RowWrapper>
          <div className="left-icon-wrapper">
            <LeftIcon type={data.type}>{LeftRenderText()}</LeftIcon>
          </div>
          <ColumnRapper>
            <span className="location">{data.location}</span>
            <span className="crime-time">{data.crimeTime}</span>
          </ColumnRapper>
        </RowWrapper>
        {data.timeline?.map((timelineEl, idx) => {
          const leftIcon = () => {
            if (timelineEl.type === '예고') return '⚠️';
            if (timelineEl.type === '발생') return '🚨';
            if (timelineEl.type === '검거완료') return '👮🏻‍♀️';
            if (timelineEl.type === '허위') return '🤬';
            return '🔫💣🔪';
          };
          return (
            <RowWrapper key={idx} style={{ justifyContent: 'flex-start' }}>
              <TimelineIconWrapper>
                <TimelineIcon type={timelineEl.type}>{leftIcon()}</TimelineIcon>
              </TimelineIconWrapper>
              <TimeLineInfoWrapper>
                <div className="timeline-type">{timelineEl.type}</div>
                <div className="timeline-createdAt">{timelineEl.createdAt}</div>
                <a
                  className="timeline-link"
                  href={timelineEl.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {timelineEl.link}
                </a>
              </TimeLineInfoWrapper>
            </RowWrapper>
          );
        })}
      </DetailInfoBoxWrapper>
    </Wrapper>
  );
};

export default BoxItem;

const Wrapper = styled.div<{ type: string; isModal?: boolean }>`
  max-width: ${({ isModal }) => (isModal ? '100%' : '350px')};
  width: 100%;
  border-radius: ${({ isModal }) => (isModal ? '0' : '10px')};
  background-color: #fff;
  padding: 16px 13px 16px 18px;

  height: auto;

  flex-shrink: 0;

  border: none;
  box-shadow: ${({ isModal }) => (isModal ? 'none' : '0 0 10px rgba(0, 0, 0, 0.1)')};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  position: relative;
  overflow: hidden;

  .crime-type {
    font-size: 0.8rem;
    font-weight: 800;
    padding: 3px 6px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .left-color-tag {
    width: 7px;
    height: 100%;
    background-color: ${({ type }) => {
      if (type === '발생') {
        return 'rgb(255, 142, 142)';
      }
      if (type === '예고') {
        return 'rgb(255, 247, 189)';
      }
      if (type === '검거완료') {
        return 'rgb(165, 210, 255)';
      }

      return 'rgba(0, 0, 0, 0.3)';
    }};

    position: absolute;
    left: 0;
    top: 0;

    border: none;
    box-shadow: none;
  }

  .location {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .time {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  .remain-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 13px;
    font-weight: 800;

    color: rgb(225, 0, 0);
  }

  .crime-time {
    font-size: 13px;
    font-weight: 500;
  }

  &:hover {
    transform-origin: 100% 0;
    /* max-width: ${({ isModal }) => (isModal ? '100%' : '360px')}; */
    box-shadow: ${({ isModal }) => (isModal ? 'none' : '0 0 10px rgba(0, 0, 0, 0.2)')};
    /* transform: scale(1.2); */
    /* border: 2px solid rgb(215, 138, 14); */
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DetailInfoBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .left-timeline-line {
    width: 2px;
    height: calc(100% - 16px);
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 16.5px;
    top: 0;
  }

  .left-icon-wrapper {
    height: 100%;
    min-width: 36px;
    width: 36px;
    max-width: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const LeftIcon = styled.div<{ type: string }>`
  min-width: 36px;
  width: 36px;
  max-width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  border-radius: 50%;
  z-index: 1;
  background-color: ${({ type }) => {
    if (type === '예고') {
      return 'rgb(255, 250, 214)';
    }
    if (type === '검거완료') {
      return 'rgb(186, 220, 255)';
    }

    return 'rgba(0, 0, 0, 0.3)';
  }};
`;

const TimelineIconWrapper = styled.div`
  min-width: 36px;
  width: 36px;
  max-width: 36px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const TimelineIcon = styled.div<{ type: string }>`
  padding: 2px;
  font-size: 12px;
  border-radius: 50%;
  z-index: 1;
  background-color: rgb(244, 244, 244);
  border: 2px solid black;
`;

const TimeLineInfoWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 4px;

  .timeline-type {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 800;
  }

  .timeline-createdAt {
    width: 100%;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .timeline-link {
    margin-top: 2px;
    width: 100%;
    padding: 10px 8px;
    border-radius: 2px;
    background-color: rgba(78, 78, 78, 0.1);
    border-radius: 5px;
    word-wrap: break-all;
    word-break: break-all;
    font-size: 0.8rem;

    color: black;

    &:hover {
      color: rgb(215, 138, 14);
    }
  }
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const ColumnRapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`;

const CopyButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  padding: 0 3px 3px 3px;

  background-color: rgba(0, 0, 0, 0.1);
  font-size: 12px;
`;
