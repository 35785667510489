import styled from '@emotion/styled';

import { CrimeDataType } from '../../../../constants/crime_list_data';
import { Spacing } from '../../../common/Spacing';

interface Props {
  data: CrimeDataType;
}

const CommentSection = (props: Props) => {
  // const commentList = [];

  const handleSubmitComment = () => {
    console.log(props ? '1' : '');
    alert('죄송합니다.\n아직 댓글 기능은 준비중입니다.\n빠른시일 내에 업데이트 하겠습니다.🙏🏻');
  };

  return (
    <Wrapper>
      <Spacing height={20} />
      <Title>댓글</Title>
      <Spacing height={15} />
      <InputSection>
        <input placeholder="댓글을 작성해주세요." />
        <div className="submit" onClick={handleSubmitComment}>
          등록
        </div>
      </InputSection>
      <CommentList>
        <EmptyComment>
          <Spacing height={20} />
          <div className="icon">⚠️</div>
          <Spacing height={15} />
          <div className="info-text">아직 댓글이 없습니다.</div>
        </EmptyComment>
      </CommentList>
    </Wrapper>
  );
};

export default CommentSection;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  input {
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #dedede;
    border-radius: 10px;
    font-size: 16px;
    line-height: 17px;
  }

  .submit {
    font-size: 13px;
    font-weight: 700;
    padding: 10px 13px;
    border-radius: 40px;
    background-color: #399fff;

    color: white;
    flex-shrink: 0;

    cursor: pointer;
  }
`;

const CommentList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const EmptyComment = styled.div`
  width: 100%;
  padding: 30px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 30px;
  }
  .info-text {
    font-size: 14px;
  }
`;
