import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Circle, MapMarker, useMap } from 'react-kakao-maps-sdk';
import { DEFAULT_PIN, SELECTED_PIN } from './markerPinImage';
import { CrimeDataType } from '../../../../constants/crime_list_data';

type Props = {
  position: {
    lat: number;
    lng: number;
  };

  item: CrimeDataType;
};

const getIsSelected = (item: CrimeDataType, selectedItemValue: CrimeDataType) => {
  return JSON.stringify(selectedItemValue) === JSON.stringify(item);
};

const CrimeMarkers = ({ position, item }: Props) => {
  const map = useMap();

  const { setValue, watch } = useFormContext();
  const selectedItemValue = watch('selectedItem');

  const markerImage = getMargerImage(item, selectedItemValue);

  const markerImageUrl = useMemo(() => {
    const isSelected = getIsSelected(item, selectedItemValue);
    isSelected && console.log('isSelected', isSelected, selectedItemValue, item);
    return isSelected ? SELECTED_PIN : DEFAULT_PIN;
  }, [item, selectedItemValue]);

  return (
    <>
      <Circle
        center={position}
        radius={item.geoLocation?.range || 1000}
        strokeWeight={1} // 선의 두께입니다
        strokeColor={'#75B8FA'} // 선의 색깔입니다
        strokeOpacity={0.1} // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
        strokeStyle={'solid'} // 선의 스타일 입니다
        fillColor={'#CFE7FF'} // 채우기 색깔입니다
        fillOpacity={0.6} // 채우기 불투명도 입니다
      />
      <MapMarker
        position={position} // 마커를 표시할 위치
        onClick={(marker: kakao.maps.Marker) => {
          // map level에 따라 마커 위치 위로 올리기
          // 하단 Info box가 가릴수도 있기 때문
          const mapLevel = map.getLevel();
          const calcLat = () => {
            switch (mapLevel) {
              case 1:
                return 0.0003;
              case 2:
                return 0.0005;
              case 3:
                return 0.001;
              case 4:
                return 0.002;
              case 5:
                return 0.004;
              case 6:
                return 0.008;
              case 7:
                return 0.015;
              case 8:
                return 0.03;
              case 9:
                return 0.06;
              case 10:
                return 0.1;
              case 11:
                return 0.2;
              case 12:
                return 0.4;
              case 13:
                return 0.8;
              case 14:
                return 1.6;
              default:
                return 0.0045;
            }
          };
          const lat = marker.getPosition().getLat() - calcLat();
          const lng = marker.getPosition().getLng();
          const moveLatLon = new kakao.maps.LatLng(lat, lng);
          map.panTo(moveLatLon);

          setValue('selectedItem', item);
        }}
        image={{
          ...markerImage,
          src: markerImageUrl,
        }}
        zIndex={markerImage?.options.zIndex}
      />
    </>
  );
};

const getMargerImage = (item: CrimeDataType, selectedItemValue: CrimeDataType) => {
  const isSelected = getIsSelected(item, selectedItemValue);

  const size = isSelected ? { width: 55, height: 55 } : { width: 35, height: 35 };
  const offset = isSelected ? { x: 0, y: 55 } : { x: 0, y: 35 };

  return {
    size: size, // 마커이미지의 크기입니다
    options: {
      offset, // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
      zIndex: isSelected ? 999 : 1,
    },
  };
};

export default CrimeMarkers;
